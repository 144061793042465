/** @jsxImportSource @emotion/react */
import './Navigation.css'
import { useRef, useState, useContext, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuOpenContext, SidebarRefContext, MenuRefContext, CloseRefContext } from './Header';
import HFButton from './parts/HeaderFooterButton';
import { css } from '@emotion/react';
import TexturedBg from '../assets/svgs/textured_bg.svg'
import { scrollToSection } from './parts/Link';

interface NavigationProps {
    isHeader: boolean;
    openModal: () => void;
}

interface NavigationItem {
    id: number;
    title: string;
    link: string;
    lang?: string;
    children?: NavigationChildItem[];
    isOnlyFooter: boolean;
}

interface NavigationChildItem {
    title: string;
    link: string;
    lang?: string;
}

export default function Navigation (props: NavigationProps) {
    const navigate = useNavigate();
    const serviceRef = useRef<HTMLDivElement>(null);
    const solutionRef = useRef<HTMLDivElement>(null);
    const serviceRightRef = useRef<HTMLDivElement>(null);
    const solutionRightRef = useRef<HTMLDivElement>(null);
    const [isOpenService, setIsOpenService] = useState(false);
    const [isOpenSolution, setIsOpenSolution] = useState(false);
    const { menuOpen, setMenuOpen } = useContext(MenuOpenContext);
    const sidebarRef = useContext(SidebarRefContext);
    const menuRef = useContext(MenuRefContext);
    const closeRef = useContext(CloseRefContext);
    // const isProduct = process.env.REACT_APP_ENV === 'production';

    const NavigationLinks: NavigationItem[] = [
        {
            id: 1,
            title: 'TOP',
            link: '/',
            lang: 'en-font-semi-bold',
            isOnlyFooter: false,
        },
        {
            id: 2,
            title: 'はばたーくとは？',
            link: '/about',
            isOnlyFooter: false,
        },
        {
            id: 3,
            title: '事業内容',
            link: '/service',
            // #3553対応
            // children: [
            //     { title: 'DX推進支援',      link: isProduct ? '/service-details/3' : '/service-details/34' },
            //     { title: '開発支援',        link: isProduct ? '/service-details/4' : '/service-details/35' },
            //     { title: 'リソース支援',    link: isProduct ? '/service-details/5' : '/service-details/36' },
            //     { title: '営業支援',        link: isProduct ? '/service-details/6' : '/service-details/37' },
            //     { title: '自社サービス',    link: 'other-service' },
            // ],
            isOnlyFooter: false,
        },
        {
            id: 4,
            title: 'ソリューション事例',
            link: '/solution',
            isOnlyFooter: false,
        },
        {
            id: 5,
            title: '採用',
            link: '/recruit',
            isOnlyFooter: false,
        },
        {
            id: 6,
            title: 'ニュース',
            link: '/news',
            isOnlyFooter: true,
        },
        {
            id: 7,
            title: '個人情報保護方針',
            link: '',
            isOnlyFooter: true,
        },
    ]

    const moveLink = (link: string) => {
        if (link === '') {
            props.openModal();
            return;
        } else if (link.includes('service-details')) {
            window.location.href = link;
        } else if (link === 'other-service') {
            navigate('/service');
            setTimeout(() => {
                scrollToSection('other-service-sp');
            }, 300);
        } else {
            navigate(link);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }

        props.isHeader&& toggleMenu();
        document.body.style.overflow = 'unset';
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (menuRef?.current && closeRef?.current && sidebarRef?.current) {
            menuRef.current.style.display = menuOpen ? 'block' : 'none';
            closeRef.current.style.display = menuOpen ? 'none' : 'block';
            sidebarRef.current.style.display = menuOpen? 'block' : 'none';
        }
    }

    const toggleChild = (link: string, isOpen: boolean) => {
        isOpen = !isOpen;
        if (link === '/service' && serviceRef.current && serviceRightRef.current) {
            serviceRightRef.current.innerHTML = isOpenService ? '＋' : 'ー';
            setIsOpenService(isOpen);
        } else if (link === '/solution' && solutionRef.current && solutionRightRef.current) {
            solutionRef.current.style.display = isOpen? 'block' : 'none';
            solutionRightRef.current.innerHTML = isOpenSolution ? '＋' : 'ー';
            setIsOpenSolution(isOpen);
        }
    }

    const navListStyle = () => [
        css`
            @media (max-width: 768px) {
                background-image: ${props.isHeader ? 'url(' + TexturedBg + ')' : 'unset' };
                background-color: ${props.isHeader ? '#fff' : '#383838' };
                color: ${props.isHeader ? 'unset' : '#fff' };
                border-bottom: ${props.isHeader ? '2px solid #EAEAEA' : '2px solid #333333' };
            }
        `
    ];

    const navRightStyle = () => [
        css`
            @media (max-width: 768px) {
                color: ${props.isHeader ? '#CCC' : '#AAA' }
            }
        `
    ];

    const navChildAreaStyle = () => [
        css`
            @media (max-width: 768px) {
                background: ${props.isHeader ? '#fff 0% 0% no-repeat padding-box' : '#555 0% 0% no-repeat padding-box'};
                color: ${props.isHeader ? 'unset' : '#fff' };
            }
        `
    ];

    const navChildIconStyle = () => [
        css`
            @media (max-width: 768px) {
                color: ${props.isHeader ? '#CCC' : '#AAA' }
            }
        `
    ];

    return (
        <div className='nav-area'>
            {
                props.isHeader ? (
                    <>
                        {
                            NavigationLinks.map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        {
                                            item.children ? (
                                                <Fragment key={item.id}>
                                                    <div css={navListStyle} className='nav-list' onClick={() => toggleChild(item.link, item.link === '/service' ? isOpenService : isOpenSolution)}>
                                                        <div className={`nav-title ${item.lang}`}>{item.title}</div>
                                                        <div css={navRightStyle} className='nav-right' ref={item.link === '/service' ? serviceRightRef : solutionRightRef}>＋</div>
                                                    </div>
                                                    <div css={navChildAreaStyle} className={`nav-child-area ${isOpenService ? 'open-child' : ''}`} ref={item.link === '/service' ? serviceRef : solutionRef}>
                                                        {
                                                            item.children.map((child, j) => {
                                                                return (
                                                                        <div key={j} className='nav-child-list' onClick={() => moveLink(child.link)}>
                                                                            <div css={navChildIconStyle} className='nav-child-icon'>→</div>
                                                                            <div className='nav-child-title'>{child.title}</div>
                                                                        </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                !item.isOnlyFooter &&
                                                <div key={item.id} css={navListStyle} className='nav-list' onClick={() => moveLink(item.link)}>
                                                    <div className={`nav-title ${item.lang}`}>{item.title}</div>
                                                    <div css={navRightStyle} className='nav-right'>→</div>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                );
                            })
                        }
                        <div className='sidenav-contact'>
                            <div onClick={() => moveLink('/contact')}>
                                <HFButton className='en-font-semi-bold contact-btn' href='/contact'>CONTACT</HFButton>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {
                            NavigationLinks.map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        {
                                            item.children ? (
                                                <Fragment key={item.id}>
                                                    <div css={navListStyle} className='nav-list' onClick={() => toggleChild(item.link, item.link === '/service' ? isOpenService : isOpenSolution)}>
                                                        <div className={`nav-title ${item.lang}`}>{item.title}</div>
                                                        <div css={navRightStyle} className='nav-right' ref={item.link === '/service' ? serviceRightRef : solutionRightRef}>＋</div>
                                                    </div>
                                                    <div css={navChildAreaStyle} className={`nav-child-area ${isOpenService ? 'open-child' : ''}`} ref={item.link === '/service' ? serviceRef : solutionRef}>
                                                        {
                                                            item.children.map((child, j) => {
                                                                return (
                                                                        <div key={j} className='nav-child-list' onClick={() => moveLink(child.link)}>
                                                                            <div css={navChildIconStyle} className='nav-child-icon'>→</div>
                                                                            <div className='nav-child-title'>{child.title}</div>
                                                                        </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                <div key={item.id} css={navListStyle} className='nav-list' onClick={() => moveLink(item.link)}>
                                                    <div className={`nav-title ${item.lang}`}>{item.title}</div>
                                                    <div css={navRightStyle} className='nav-right'>→</div>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                );
                            })
                        }
                    </>
                )
            }
        </div>
    );
}