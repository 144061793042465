/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import normalArrowImg from "../../assets/images/circled_rt_arrow_white_bg@2x.png";
import hoverArrowImg from "../../assets/images/circled_rt_arrow_black_bg@2x.png";

interface ButtonProps {
    children: ReactNode;
    onClick?: () => void;
    className?: string;
    widthPc?: string;
    widthSp?: string;
    heightPc?: string;
    heightSp?: string;
    color?: string;
    bgColor?: string;
    border?: string;
    borderRadius?: string;
    boxShadow?: string;
    hoverColor?: string;
    hoverBgColor?: string;
    fontSizePc?: string;
    fontSizeSp?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    circleWidthPc?: string;
    circleWidthSp?: string;
    circleMarginRPc?: string;
    circleMarginRSp?: string;
    isBackArrow?: boolean;
};

export default function Button(props: ButtonProps) {
    // Default values
    const defaultBtnWidthPc = '440px';
    const defaultBtnHeightPc = '64px';
    const defaultBtnWidthSp = '82vw';
    const defaultBtnHeightSp = '13.5vw';
    const defaultBorder = '2px solid #333';
    const defaultBoxShadow = '0px 2px 2px #00000026';
    const defaultBorderRadius = '100px';
    const defaultBgColor = '#333';
    const defaultHoverBgColor = '#fff';
    const defaultHoverColor = '#333';
    const defaultColor = '#fff';
    const defaultFontSizePc = '18px';
    const defaultFontSizeSp = '3.7vw';
    const defaultMarginTop = '0';
    const defaultMarginBottom = '0';
    const defaultMarginRight = '0';
    const defaultCircleWidthPc = '2.5vw';
    const defaultCircleWidthSp = '8vw';
    const defaultIsBackArrow = false;

    const [isHover, setIsHover] = useState(false);
    const [, setIsClicked] = useState(false);
    const navigate = useNavigate();
    const targetRefButton = useRef(null);

    const isBackArrow = props.isBackArrow || defaultIsBackArrow;

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        } else {
            navigate("/contact");
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }, 100)
        }
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 200);
    };

    const buttonStyle = () => [
        css`
            box-sizing: border-box;
            width: ${props.widthPc || defaultBtnWidthPc};
            height: ${props.heightPc || defaultBtnHeightPc};
            margin-top: ${props.marginTop || defaultMarginTop};
            margin-bottom: ${props.marginBottom || defaultMarginBottom};
            margin-right: ${props.marginRight || defaultMarginRight};
            border: ${props.border || defaultBorder};
            box-shadow: ${props.boxShadow || defaultBoxShadow};
            border-radius: ${props.borderRadius || defaultBorderRadius};
            background-color: ${props.bgColor || defaultBgColor};
            color: ${props.color || defaultColor};
            text-align: center;
            cursor: pointer;
            transition: background 0.5s;
            padding-inline: 0.417vw;
            &:hover {
                color: ${props.hoverColor || defaultHoverColor};
                background-color: ${props.hoverBgColor || defaultHoverBgColor};
            }
            &:active {
                color: ${props.color || defaultColor};
                background-color: ${props.bgColor || defaultBgColor};
                transition: background 0.2s;
            }
            @media (max-width: 768px) {
                width: ${props.widthSp || defaultBtnWidthSp};
                height: ${props.heightSp || defaultBtnHeightSp};
                margin-top: ${props.marginTop || defaultMarginTop};
                margin-bottom: ${props.marginBottom || defaultMarginBottom};
            }
        `
    ];

    const divStyle = () => [
        css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: 'Noto Sans JP';
            position: relative;
        `
    ];

    const textStyle = () => [
        css`
            font-size: ${props.fontSizePc || defaultFontSizePc};
            @media (max-width: 768px) {
                font-size: ${props.fontSizeSp || defaultFontSizeSp};
                font-weight: 500;
            }
        `
    ];

    const circledArrow = (isHover: boolean, isBackArrow: boolean) => [
        css`
            background-image: url(${isHover ? hoverArrowImg : normalArrowImg});
            background-size: cover;
            background-position: center;
            width: ${props.circleWidthPc || defaultCircleWidthPc};
            height: ${props.circleWidthPc || defaultCircleWidthPc};
            position: absolute;
            ${isBackArrow ? 'left' : 'right'}: 0.417vw;
            transform: ${isBackArrow ? 'rotate(180deg)' : 'rotate(0deg)'};
            @media (max-width: 768px) {
                width: ${props.circleWidthSp || defaultCircleWidthSp};
                height: ${props.circleWidthSp || defaultCircleWidthSp};
                max-height: 100px;
                ${isBackArrow ? 'left' : 'right'}: 1.8vw;
            }
        `
    ];

    return (
        <>
            <button
                css={buttonStyle}
                onClick={handleClick}
                className={props.className}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                ref={targetRefButton}
            >
                <div css={divStyle}>
                    <span css={textStyle}>{props.children}</span>
                    <div css={circledArrow(isHover, isBackArrow)}></div>
                </div>
            </button>
        </>
    );
}
