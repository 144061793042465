import './Footer.css'
import Logo from '../assets/images/common/logo-dark.png';
import HFButton from './parts/HeaderFooterButton';
import { createContext, useState } from 'react';
import Navigation from './Navigation';
import Modal from './parts/Modal';
import TermsAndConditions from './TermsAndConditions'; 
// import { scrollToSection } from './parts/Link';
// import { useNavigate } from 'react-router-dom';

export const MenuOpenContext = createContext({} as {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
});

export const SidebarRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export const MenuRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export const CloseRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export default function Footer() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const isProduct = process.env.REACT_APP_ENV === 'production';
    // const navigate = useNavigate();

    const LinkLogo = () => {
        return (
            <div className='logo'>
                <a href="/">
                    <img src={Logo} alt="株式会社はばたーく" className='footer-logo-img' />
                </a>
            </div>
        )
    }

    const openModal = () => {
        setIsModalOpen(true);
        document.body.classList.add('no-scroll');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('no-scroll');
    };

    // const moveServiceDetail = (link: string) => {
    //     if (link === '/service') {
    //         navigate(link);
    //         setTimeout(() => {
    //             scrollToSection('other-service-pc');
    //         }, 300);
    //     } else {
    //         document.location.href = link;
    //     }
    // }

    return (
        <>
            {/* PC画面 */}
            <footer className="pc-content">
                <div className="footer-container">
                    <div className="footer-left">
                        <div className="footer-logo">
                            <img src={Logo} alt="Logo" />
                        </div>
                        <HFButton
                            href='/contact' 
                            className='en-font-semi-bold' 
                            width='14vw'
                            height='2,8vw'
                            paddingLeft='0'
                            paddingRight='0'
                            fontSize='1.11vw'
                            bgColor='transparent'
                            borderColor='#fff'
                        >
                                CONTACT
                        </HFButton>
                        <div className="footer-copy">
                            © haveatalk, Inc.
                        </div>
                    </div>
                    <div className="footer-links footer-right">
                        <div className="footer-links-div"><a href="/" className="en-font-semi-bold">TOP</a></div>
                        <div className="footer-links-div"><a href="/about">はばたーくとは？</a></div>
                        <div className="footer-links-div">
                            <a href="/service">事業内容</a>
                            {/* #3553対応 */}
                            {/* <p>
                                <span onClick={() => moveServiceDetail(isProduct ? '/service-details/3' : '/service-details/34')}>DX推進支援</span>
                                <span onClick={() => moveServiceDetail(isProduct ? '/service-details/4' : '/service-details/35')}>開発支援</span>
                                <span onClick={() => moveServiceDetail(isProduct ? '/service-details/5' : '/service-details/36')}>リソース支援</span>
                                <span onClick={() => moveServiceDetail(isProduct ? '/service-details/6' : '/service-details/37')}>営業支援</span>
                                <span onClick={() => moveServiceDetail('/service')}>自社サービス</span>
                            </p> */}
                        </div>
                        <div className="footer-links-div">
                            <a href="/solution">ソリューション事例</a>
                        </div>
                        <div className="footer-links-div"><a href="/recruit">採用</a></div>
                        <div className="footer-links-div"><a href="/news">ニュース</a></div>
                        <div className="footer-links-div last"><button onClick={openModal}>個人情報保護方針</button></div>
                    </div>
                    <div className="background"></div>
                </div>
            </footer>

            {/* SP画面 */}
            <footer className="sp-content">
            <div className="footer-bottom">
                <Navigation isHeader={false} openModal={openModal} />
                <LinkLogo/>
                <HFButton
                        href='/contact' 
                        className='en-font-semi-bold' 
                        width='58.667vw'
                        height='10.667vw'
                        paddingLeft='0'
                        paddingRight='0'
                        marginTop='6.667vw'
                        marginBottom='6.667vw'
                        fontSize='3.733vw'
                        bgColor='transparent'
                        borderColor='#fff'
                    >
                            CONTACT
                    </HFButton>
                    <div className="footer-copy">
                        <p>© haveatalk, Inc.</p>
                    </div>
                </div>
            </footer>

             {/* Modal */}
             <Modal show={isModalOpen} onClose={closeModal}>
                <TermsAndConditions />
            </Modal>
        </>
    );
}