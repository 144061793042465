import './Header.css';
import Logo from '../assets/images/common/logo@3x.png';
import Link from './parts/Link';
import HFButton from './parts/HeaderFooterButton';
import { useState, useRef, createContext, useEffect, useCallback } from 'react';
import Navigation from './Navigation';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import { useLocation } from 'react-router-dom';

export const MenuOpenContext = createContext({} as {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
});

export const SidebarRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export const MenuRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export const CloseRefContext = createContext<React.RefObject<HTMLDivElement> | null>(null);

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const closeRef = useRef<HTMLDivElement>(null);
    const body = document.body;
    const headerRef = useRef<HTMLDivElement>(null);
    const isPC = useMediaQuery(mediaQuery.pc);
    const location = useLocation(); 

    const addBoxShadow = useCallback(() => {
        const element = headerRef.current;
        if (element) {
            if (window.scrollY === 0) {
                element.style.boxShadow = 'none';
            } else {
                element.style.boxShadow = isPC ? '0px 0px 10px #3333331A' : '0px 0px 20px #3333331A';
            }
        }
    }, [isPC])

    useEffect(() => {
        window.addEventListener('scroll', addBoxShadow);

        const handleBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        const solutionPath = '/solution';
        if (location.pathname !== solutionPath) {
            sessionStorage.removeItem('currentSolutionPageNo');
        }
        const newsPath = '/news';
        if (location.pathname !== newsPath) {
            sessionStorage.removeItem('currentNewsPageNo');
        }

        return () => {
            window.removeEventListener('scroll', addBoxShadow);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [addBoxShadow, location.pathname]);

    const LinkLogo = () => {
        return (
            <div className='logo'>
                <a href="/">
                    <img src={Logo} alt="株式会社はばたーく" className='logo-img' />
                </a>
            </div>
        )
    }

    const openMenu = () => {
        if (sidebarRef.current && menuRef.current && closeRef.current) {
            body.style.overflow = 'hidden';
            sidebarRef.current.style.display = 'block';
            menuRef.current.style.display = 'none';
            closeRef.current.style.display = 'block';
            setTimeout(() => {
                setMenuOpen(true);
            }, 10)
        }
    }

    const closeMenu = () => {
        if (sidebarRef.current && menuRef.current && closeRef.current) {
            body.style.overflow = 'unset';
            menuRef.current.style.display = 'block';
            closeRef.current.style.display = 'none';
            setMenuOpen(false);
        }
    }

    return (
        <>
        {
            isPC ? (
                // PC画面
                <div className="pc-content">
                    <header ref={headerRef}>
                        <nav className="navbar">
                            <div className="nav-container">
                                <LinkLogo />
                                <ul className="nav-links">
                                    <Link className="en-font" 
                                        fontSize="1.042vw"
                                        fontWeight="600" 
                                        letterSpacing="0.104vw" 
                                        isHeaderPC
                                        href="main-vs">TOP</Link>
                                    <Link href="about" className="bold" fontSize='0.972vw' isHeaderPC>はばたーくとは？</Link>
                                    <Link href="service" className="bold" fontSize='0.972vw' isHeaderPC>事業内容</Link>
                                    <Link href="/solution" className="bold" fontSize='0.972vw'>ソリューション事例</Link>
                                    <Link href="recruit" className="bold" fontSize='0.972vw' isHeaderPC>採用</Link>
                                </ul>
                                <HFButton className='en-font-semi-bold' href='contact' isHeaderPC width='11.111vw' fontSize='1.042vw'>CONTACT</HFButton>
                            </div>
                        </nav>
                    </header>
                </div>
            ) : (
                // SP画面
                <div className='sp-content'>
                    <header ref={headerRef}>
                        <MenuOpenContext.Provider value={{menuOpen, setMenuOpen}}>
                        <SidebarRefContext.Provider value={sidebarRef}>
                        <MenuRefContext.Provider value={menuRef}>
                        <CloseRefContext.Provider value={closeRef}>
                            <nav className='navbar'>
                                <LinkLogo />
                                <div className={`menu ${menuOpen ? 'fadeout' : 'fadein'}`} onClick={openMenu} ref={menuRef}>
                                    <span className='material-symbols-outlined menu-icon'>menu</span>
                                </div>
                                <div className={`close ${menuOpen ? 'fadein' : 'fadeout'}`} onClick={closeMenu} ref={closeRef}>
                                    <span className='material-symbols-outlined close-icon'>close</span>
                                </div>
                            </nav>
                            <div className={`sidebar ${menuOpen ? 'open' : 'close' }`} ref={sidebarRef}>
                                <Navigation isHeader={true} openModal={()=>null} />
                            </div>
                        </CloseRefContext.Provider>
                        </MenuRefContext.Provider>
                        </SidebarRefContext.Provider>
                        </MenuOpenContext.Provider>
                    </header>
                </div>
            )
        }
        </>
    );
}