import './SolutionDetails.css'
import TopicTitlePath from '../components/parts/TopicTitlePath';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import NotFound from '../components/NotFound';
import { useSolution } from '../api/useSolution';
import Loading from '../components/parts/Loading';
import { Space, HStack } from '../components/parts/SharedLayout';
import { mediaQuery, useMediaQuery } from '../hooks/useMediaQuery';
import { SolutionPoint } from './SolutionDetails';
import Category from '../components/parts/Category';

export default function SolutionPreview() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('preview_token');
    const { solutionDetails, getPreview, isLoading } = useSolution();
    const isSP = useMediaQuery(mediaQuery.sp);

    useEffect(() => {
        if (token) {
            console.log(token);
            getPreview(token);
        }
    }, [getPreview, token]);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    if (!solutionDetails.details.topics_id) {
        return (
            <>
                <NotFound link='/solution'>ソリューション事例に戻る</NotFound>
            </>
        );
    }

    return (
        <>
            {
                <div>
                    <TopicTitlePath topicMain={solutionDetails.details.subject} topicSub='SOLUTION' topicPath={[{ name: '株式会社はばたーく', link: '/' }, { name: 'ソリューション事例', link: '/solution' }, { name: solutionDetails.details.subject, link: `/solution-details/${solutionDetails.details.topics_id}` }]} />
                    <div className='topic-1'>
                        <div className='subject'>{solutionDetails.details.subject}</div>
                        {
                            solutionDetails.details.subtitle &&
                            <div className='subtitle'>{solutionDetails.details.subtitle}</div>
                        }
                        <img className='eyecatch' src={solutionDetails.details.image.url_org} alt="" />
                        <HStack className='sol-tag-area'>
                            {
                                solutionDetails.details.categories.length > 0 &&
                                solutionDetails.details.categories.map((category, index) => (
                                    <Category key={index} label={category.label} />
                                ))
                            }
                        </HStack>
                        {
                            (solutionDetails.details.points.length > 0) &&
                            <>
                                <Space height={`${isSP ? '15.333vw' : '5.556vw'}`} />
                                <SolutionPoint points={solutionDetails.details.points} />
                            </>
                        }
                    </div>
                    {
                        (solutionDetails.details.contents1 || solutionDetails.details.contents2 || solutionDetails.details.contents3) &&
                        <div className='topic-2'>
                            <div>{parse(solutionDetails.details.contents1)}</div>
                            {
                                (solutionDetails.details.highlights_subject && solutionDetails.details.highlights_text) &&
                                <div className='white-space flex-col'>
                                    <div className='flex header'>
                                        <div className='yellow-point-m margin-t'></div>
                                        <div className='highlights-subject'>{solutionDetails.details.highlights_subject}</div>
                                    </div>
                                    <div className='highlights-text'>{solutionDetails.details.highlights_text}</div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </>
    );
}