import './News.css'
import { Fragment, useState, useEffect } from 'react';
import TopicTitlePath from '../components/parts/TopicTitlePath';
import { TopicPath } from "../components/parts/TopicTitlePath";
import { useNews } from '../api/useNews';
import { useNavigate } from 'react-router-dom';
import Pagination from '../components/parts/Pagination';
import Loading from '../components/parts/Loading';

export const TopicPathData: TopicPath[] = [
    { name: '株式会社はばたーく', link: '/' },
    { name: 'ニュース', link: '/news' },
];

export default function News() {
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [lastPageNo, setLastPageNo] = useState<number>(0);
    const { isLoading, newsListData, getList } = useNews();
    const navigate = useNavigate();

    useEffect(() => {
        const savedPageNo = sessionStorage.getItem('currentNewsPageNo');
        if (savedPageNo) {
            setCurrentPageNo(Number(savedPageNo));
            getList({ pageId: Number(savedPageNo) });
        } else {
            getList({ pageId: currentPageNo });
        }

        setLastPageNo(newsListData.pageInfo.totalPageCnt);
    }, [currentPageNo, getList, newsListData.pageInfo.totalPageCnt]);

    const handleChangePage = (pageNo: number) => {
        setCurrentPageNo(pageNo);
        sessionStorage.setItem('currentNewsPageNo', pageNo.toString());
        getList({ pageId: pageNo });
    }

    const moveDetail = (id: number) => {
        navigate('/news-details/' + id);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            {/* PC画面 */}
            <div className="pc-content">
                <TopicTitlePath
                    topicSub='NEWS'
                    topicMain='ニュース'
                    topicPath={TopicPathData}
                />
                <div className='news-site'>
                    <div className='news-container'>
                        {
                            <>
                                {
                                    newsListData.list &&
                                    newsListData.list.map((news, index) => (
                                        <Fragment key={index}>
                                            <div className={`news-wrapper ${newsListData.list.length - 1 === index && 'margin-b-100'}`} onClick={() => moveDetail(news.topics_id)}>
                                                <div className='news-upper flex'>
                                                    <div className='date en-font'>{news.ymd.split('-').join('.')}</div>
                                                    <div className='flex news-category-area'>
                                                        {
                                                            news.categories && 
                                                            news.categories.map((category, index) => (
                                                                <div key={index} className='tag'>{category.label}</div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className='news-contents-wrap'>
                                                    <div className='news-title'>{news.subject}</div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))
                                }
                            </>
                        }
                        <Pagination currentPageNo={currentPageNo} lastPageNo={lastPageNo} onClick={handleChangePage} />
                    </div>
                </div>
            </div>

            {/* SP画面 */}
            <div className="sp-content">
                <TopicTitlePath
                    topicSub='NEWS'
                    topicMain='ニュース'
                    topicPath={TopicPathData}
                />
                <div className='news-site'>
                    <div className='news-container'>
                        {
                            <>
                                {
                                    newsListData.list &&
                                    newsListData.list.map((news, index) => (
                                        <Fragment key={index}>
                                            <div className='news-wrapper' onClick={() => moveDetail(news.topics_id)}>
                                                <div className='news-upper'>
                                                    <div className='date en-font'>{news.ymd.split('-').join('.')}</div>
                                                    <div className='flex news-category-area'>
                                                        {
                                                            news.categories && 
                                                            news.categories.map((category, index) => (
                                                                <div key={index} className='tag'>{category.label}</div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className='news-contents-wrap'>
                                                    <div className='news-content'>{news.subject}</div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))
                                }
                            </>
                        }
                        <Pagination currentPageNo={currentPageNo} lastPageNo={lastPageNo} onClick={handleChangePage} />
                    </div>
                </div>
            </div>
        </>
    );
}
